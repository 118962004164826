$(function(){
    $('#selDpt').change(function(){
        $("#selLine option").remove();
        $("#selCls option").remove();
        var id = $('#selDpt').find(":selected").val();
        var url = $('#selLine').data('url');
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url : url,
            data: {
                "id": id
            },
            type: 'POST',
            dataType: 'json',
            success: function( result )
            {
                $("#selLine").append(new Option("ライン", ""));
                $("#selCls").append(new Option("クラス", ""));
                $.each( result, function(k, v) {
                    $('#selLine').append($('<option>', {value:v.linecd, text:v.linenm}));
                });
            },
            error: function()
            {
                //handle errors
            }
        });
    });
    $('#selLine').change(function(){
        $("#selCls option").remove();
        var id_dpt = $('#selDpt').find(":selected").val();
        var id_line = $('#selLine').find(":selected").val();
        var url_cls = $('#selCls').data('url');
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url : url_cls,
            data: {
                "id_dpt" : id_dpt,
                "id": id_line,
            },
            type: 'post',
            dataType: 'json',
            success: function( result )
            {
                $("#selCls").append(new Option("クラス", ""));
                $.each( result, function(k, v) {
                    $('#selCls').append($('<option>', {value:v.clscd, text:v.clsnm}));
                });
            },
            error: function()
            {
                //handle errors
            }
        });
    });

    $('.home-item-title.active_2').on('click', function(){
        $(this).next('.home-item-body').slideToggle(300);
        $('.home-item-title.active_2').toggleClass('hide-item');
    });

    $('.home-item-title.active_1').on('click', function(){
        $(this).next('.home-item-body').slideToggle(300);
        $('.home-item-title.active_1').toggleClass('hide-item');
    });
});
